.flexcolumn {
    height:100%;
    display:flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width:100%;
    z-index:0;
}

.flexrow {
    width:calc(100% - 4px);
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    z-index:0;
}

.subcolumn {
    width:100%;
}

.playerSector {
    position:absolute;
    z-index:0;
}

#sector1of1 {
    height:100vmin;
    width:50vmax;
}

#sector1of2, #sector2of2 {
    height:45vmax;
    width:100vmin;
    transform-origin: left top;
}

#sector1of2 {
    transform: translateX(45vmax) rotate(90deg);
}

#sector2of2 {
    transform:translateX(55vmax) translateY(100vmin) rotate(-90deg);
}

#sector1of3, #sector1of4 {
    transform:rotate(180deg);
}

#sector2of3 {
	transform:translateX(50vmax) rotate(180deg);
}

#sector3of3, #sector4of4 {
	transform:translateY(50vmin);
}

#sector1of3, #sector2of3, #sector3of3{
    height:50vmin;
	width:50vmax;
}
#sector1of4, #sector2of4, #sector3of4, #sector4of4{
    height:50vmin;
	width:45vmax;
}

#sector2of4 {
	transform:translateX(55vmax) rotate(180deg);
}

#sector3of4 {
	transform:translateX(55vmax) translateY(50vmin);
}

#sector1of5, #sector2of5, #sector3of5, #sector4of5, #sector5of5, #sector6of5 {
    height:50vmin;
    width:calc(100vmax/3);
}

#sector1of5 {
    transform:rotate(180deg);
}

#sector2of5 {
    transform:translateX(calc(100vmax/3)) rotate(180deg);
}
#sector3of5 {
    transform:translateX(calc(100vmax - (100vmax/3))) rotate(180deg);
}
#sector5of5 {
	transform:translateY(50vmin);
}
#sector4of5 {
    transform:translateX(calc(100vmax/3)) translateY(50vmin);
}

#sector1of6, #sector2of6, #sector3of6, #sector4of6, #sector5of6, #sector6of6 {
    height:50vmin;
    width:calc(100vmax/3 - (10vmax/3));
}

#sector1of6 {
    transform:rotate(180deg);
}

#sector2of6 {
    transform:translateX(calc(100vmax/3 + (10vmax/1.5))) rotate(180deg);
}
#sector3of6 {
    transform:translateX(calc(100vmax - (100vmax/3) + (10vmax/3))) rotate(180deg);
}
#sector6of6 {
	transform:translateY(50vmin);
}
#sector5of6 {
    transform:translateX(calc(100vmax/3 + (10vmax/1.5))) translateY(50vmin);
}
#sector4of6 {
    transform:translateX(calc(100vmax - (100vmax/3) + (10vmax/3))) translateY(50vmin);
}



.playersectorrightcolumn {
    width:50%;
    padding:0px;
}

.playersectorleftcolumn {
    padding:0px;
    width:50%;
}


button:focus {
    outline: 0;
}

.healthButton
{
    background-color:transparent;
    border:none;
    height:10vmin;
    width:10vmin;
    padding:0px;
    text-decoration:none;
}

.commanderButton
{
    background-color:transparent;
    border:none;
    height:6vmin;
    width:6vmin;
    padding:0px;
    text-decoration:none;
}

.playersectormaincounter {
    height:20vmin;
}
.counterButton {
    width:auto;
    height:auto;
    background-color:transparent;
    border:none;
    padding:0px;
    text-decoration:none;
}

.popupwrapper {
    display:flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width:100%;
    height:10vmin;
    font-size:5vmin;
}

.popup {
    z-index:9999;
    font-size:6vmin;
    padding-left:3vmin;
    padding-right:3vmin;
    text-align: center;
    background-color:white;
    height:9vmin;
    transform-origin: center center;
    border-radius:5vmin;
    width:auto;
}

.hidden {
    display:none;
}

.commanderDamagePanel {
    height:auto;
    width:auto;
    min-width:8vmin;
    font-size:5.5vmin;
    border-radius:1vmin;
    border:2px solid black;
    display:inline-block;
    /*box-shadow: 0 0 3vmin rgba(0,0,0, 0.5);*/
}

.commanderDamageWrapper
{

    flex-wrap: wrap;
    justify-content: space-evenly;
}

.selected {
    border: 2px solid red;
    margin: 0px;
}

.pulsing {
    animation-name:color;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
        color:red;
    }

    50% {
        color:inherit;
    }

    100% {
        color:red;
    }
}

.highlighted {
    animation-name:blink;
    animation-duration: 0.5s;
    animation-iteration-count: 5;
}

@keyframes blink {
    50% {
        background-color:white;
    }
}

.onedigit {
    font-size:20vmin;
}

.twodigits {
    font-size: 20vmin;

}

.threedigits {
    font-size:18vmin;

}

.fourdigits {
    font-size:16vmin;

}

.moredigits {
    font-size:14vmin;

}

