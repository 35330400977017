.spinnerPanel {
    position:absolute;
    height:50vmin;
    width:50vmax;
    z-index:9999;
    background-color:white;
    left:calc(25vmax - 2px);
    top:calc(25vmin - 2px);
    border-radius:2vmin;
    border: 2px solid black;
    box-shadow: 0 0 10vmin rgba(0,0,0,0.8);
    font-size:6vmin;
    flex-wrap:wrap;

}

button {
    border-radius:1vmin;
    height:8vmax;
    width:8vmax;
    border:2px solid black;
    font-size:4vmin;
}

.spinnerSelectionRow {
    height:20vmin;
    flex-wrap: wrap;
}

.spinnerSelectionButton {
    height: 10vmin;
    margin-bottom: 1vmin;
    width:auto;
}

.spinnerSelectionButton.selected {
    border-width: 0.3vmin;
}