
.Menu {
    font-family: "Blinker", sans-serif;
    position: absolute;
    z-index: 10;
    width: calc(100vmax - 10vmin);
    height: 90vmin;
    left: 5vmin;
    top: 5vmin;
    background-color:lightgrey;
    display:flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items:center;
    border-radius:2vmin;
    border:2px solid black;
    box-shadow:0 0 5vmin rgba(0, 0, 0, 0.8);
}

.Menu.hidden {
    display: none;
}

.menu_player_count_section, .menu_starting_health_section, .menu_partner_commander_toggle, .menu_player_names {
    width:100%;
    font-size: 5vmin;
    display:flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.leftdivcolumn {
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding-right:2.5vmin;
}

.rightdivcolumn {
    padding-left:2.5vmin;
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

a {
    text-decoration:none;
    color:inherit;
}
.player_count_counter, .starting_health_counter {
    padding:0 2.5vmin 0 2.5vmin;
    margin:0;
}
.button
{
    background-color:transparent;
    border:none;
    height:5vmin;
    width:5vmin;
    padding:0px;
    text-decoration:none;
    border-radius:1vmin;
}

.menu_title {
    margin-top:2vmin;
    font-size: 10vmin;
}

.successbutton , .cancelbutton, .resetbutton {
    margin:2vmin;
    height: 6vmin;
    width:auto;
    border: 1px solid black;
    display:flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding:1vmin;
}

.successbutton > img, .cancelbutton > img {
    height:4vmin;
    margin-right:2vmin;
}

.close_buttons {
    display: flex;
    flex-flow: row nowrap;
}


.toggled {
    background-color: red;
}

.commanderSelect, .keyboardButton, .playerNameButton {
    padding-left:1vmin;
    padding-right:1vmin;
    margin-left:1vmin;
    margin-right:1vmin;
    border: 2px solid black;
    border-radius:1vmin;
}

.keyboardButton {
    width: 4vmax;
    height: 4vmax;
    margin:0.2vmax;
}

.playerNameButton {
    width:auto;
}

.keyboardButton.autowidth {
    width:auto;
}

.keyboardButton:active {
    background-color: red;
}

.menu_partner_commander_toggle > button {
    width:auto;
    height:6vmin;
    font-size: 4vmin;
}

.resetbutton {
    color:red;
}

.menu_keyboard {
    width:auto;
}
.nosleep {
    border: 1px solid black;
    width:auto;
}

.menu_tab_wrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height:100%;
    background-color: white;
    border-radius:2vmin;

}

.menu_tab_buttons {
    display:flex;
    flex-flow: column nowrap;
    width:15%;
    height:100%;
}

.menu_tab_button {
    width:100%;
    border-right:none;
    border-radius: 0;
    background-color: white;
    border-top: none;
    border-left:none;
}

.menu_tab_content_wrapper {
    display:flex;
    flex-flow:column nowrap;
    width:85%;
    height:100%;
    justify-content: space-between;
    align-items:center;
}

.menu_tab_content {
    display:flex;
    flex-flow:column nowrap;
    width:85%;
    height:100%;
    justify-content: space-around;
    align-items:center;
}

.menu_tab_content.hidden {
    display:none;
}

.menu_tab_button_inactive {
    background-color: grey;
    color: lightgrey;
    border-right: 1px solid black;
}

.menu_keyboard_line, .menu_keyboard_modifiers {
    width:auto;
    text-align: center;
}

.menu_tab_info {
    font-size: 5vmin;
}