.massDamagePanel {
    position:absolute;
    height:50vmin;
    width:50vmax;
    z-index:9999;
    background-color:white;
    left:calc(25vmax - 2px);
    top:calc(25vmin - 2px);
    border-radius:2vmin;
    border: 2px solid black;
    box-shadow: 0 0 10vmin rgba(0,0,0,0.8);
}