.spinner {
    z-index:9998;
    position:absolute;
    padding:0;
    margin:0;
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color:black;
    opacity:0.95;
    top:0;
    left:0;
    height:100vmin;
    width:100vmax;
}

#spinnerCanvas {
    position:absolute;
    height:100vmin;
    width:100vmin;
    z-index:9999;
}

.hidden { 
    display:none;
}

.backboard {
    position:absolute;
    display:block;
    height:100vmin;
    width:100vmin;
    z-index:9998;
    transform: rotate(180deg);
}

.svgText {
    font: italic 0.1px sans-serif; fill: black;
}