.commanderUsedButton {
    width:auto;
    height:auto;
    background-color:transparent;
    border:none;
    padding:0px;
    text-decoration:none;
    display:flex;
    flex-flow:column nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.buttonimg {
    height:5.5vmin;
    width:5.5vmin;

}

.commanderUsed {
    padding-top:3px;
    padding-bottom:3px;
    border:2px solid black;
    border-radius:1vmin;
    height:5.5vmin;
    background-color:rgba(255, 255, 255, 0.5);
    /*box-shadow: 0 0 3vmin rgba(0,0,0, 0.5);*/
}

.commanderUsedCounter {
    font-size:5.5vmin;
    line-height: 5.5vmin;
    opacity:1.0;
    height:auto;
 }
