.App {
  text-align: center;
}


#updateAvailablePrompt {
  position:absolute;
  top:90vmin;
  left:0;
  height:10vmin;
  width:100vmax;
  text-align:center;
  background:black;
  color:white;
  border:none;
  text-decoration: none;
}


.hidden {
  display:none;
}

#keepAwake {
  position:absolute;
  display:none;
  left:0;
  top:0;
}


.player1 {
  background-color:darkorchid;
}

.player2 {
  background-color:chocolate;
}

.player3 {
  background-color:darkcyan;
}

.player4 {
  background-color:khaki;
}

.player5 {
  background-color:firebrick;
}

.player6 {
  background-color:lightskyblue;
}

.appportrait {
  transform-origin: top-left;
  transform: rotate(90deg) translate(50vmin, -50vmin) ;
}
